import {Box, Text} from "@chakra-ui/react";
import React from "react";

export function TextBox({
                            text,
                            show,
                            customTextClass,
                            customParentClass,
                            whiteHex,
                            blackHex,
                        }: { text: string, show: boolean, customTextClass?: string, customParentClass?: string, whiteHex?: string, blackHex?: string }) {
    const [t1, setT1] = React.useState<"o:i" | "o:m" | "o:o" | "c:i" | "c:m" | "c:o">('o:i');
    const [t2, setT2] = React.useState<"o:i" | "o:m" | "o:o" | "c:i" | "c:m" | "c:o">('o:i');

    const [appear, setAppear] = React.useState(false);

    const t1R = React.useRef<NodeJS.Timeout[]>([]);
    const t2R = React.useRef<NodeJS.Timeout[]>([]);
    const t = React.useRef<NodeJS.Timeout[]>([]);

    React.useEffect(() => {
        t.current.map(t => clearTimeout(t));

        if (show) {
            doThings(setT1, true, true);
            t.current.push(setTimeout(() => doThings(setT2, true, false), 200));
            t.current.push(setTimeout(() => setAppear(true), 350));
            console.log(1)
        } else {
            doThings(setT1, false, true);
            t.current.push(setTimeout(() => doThings(setT2, false, false), 200));
            t.current.push(setTimeout(() => setAppear(false), 350));
            console.log(2)
        }

        console.log(`now ${show ? 'showing' : 'hiding'} text`);
    }, [show]);

    function doThings(func: React.Dispatch<React.SetStateAction<"o:i" | "o:m" | "o:o" | "c:i" | "c:m" | "c:o">>, open: boolean, tr1: boolean) {
        if (open) {
            (tr1 ? t1R.current : t2R.current).map(t => clearTimeout(t));
            func('o:i');
            const t1 = setTimeout(() => func('o:m'), 100);
            const t2 = setTimeout(() => func('o:o'), 500);

            if (tr1) t1R.current.push(t1, t2);
            else t2R.current.push(t1, t2);

        } else {
            (tr1 ? t1R.current : t2R.current).map(t => clearTimeout(t));
            func('c:i');
            const t1 = setTimeout(() => func('c:m'), 100);
            const t2 = setTimeout(() => func('c:o'), 500);

            if (tr1) t1R.current.push(t1, t2);
            else t2R.current.push(t1, t2);
        }
    }

    function getTransition(str: "o:i" | "o:m" | "o:o" | "c:i" | "c:m" | "c:o") {
        switch (str) {
            case 'o:i':
                return "text-box-open-intro";
            case 'o:m':
                return "text-box-open-middle";
            case 'o:o':
                return "text-box-open-outro";

            case 'c:i':
                return "text-box-close-intro";
            case 'c:m':
                return "text-box-close-middle";
            case 'c:o':
                return "text-box-close-outro";

            default:
                return "text-box-open-intro";
        }
    }

    return (
        <Box className={`text-box-container unselectable ${customParentClass}`}>
            <Box className={`text-box-transition text-box-container-transition-1 ${getTransition(t1)}`} style={blackHex ? {backgroundColor: blackHex} : {}}/>
            <Box className={`text-box-transition text-box-container-transition-2 ${getTransition(t2)}`} style={whiteHex ? {backgroundColor: whiteHex} : {}}/>
            <Text className={`text-box-text ${customTextClass} ${appear ? "" : "text-box-text-hide"}`}>{text}</Text>
        </Box>
    )
}