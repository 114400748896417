import {Box, Text} from "@chakra-ui/react";
import React, {useEffect} from "react";
import "./CSS/main.css";
import projects from "../data/projects";
import contacts from "../data/contacts";
import languages from "../data/languages";

export function Mobile() {
    const [confirmed, setConfirmed] = React.useState(localStorage.getItem("confirmed") === "true");
    const [texts, setTexts] = React.useState<{text: string, type: 'output' | 'input', disabled?: boolean}[]>([]);
    const [input, setInput] = React.useState("");

    function addText(type: 'output' | 'input' | 'output:link', text: string) {
        if (type === "output:link") {
            text = "img:" + text;
            type = "output";
        }
        //@ts-ignore
        setTexts(d => {
            if (type === "input") d = d.map((x) => x.type === "input" ? {...x, disabled: true} : x);
            return [...d, {text, type}];
        });
    }

    useEffect(() => console.log(texts), [texts]);

    useEffect(() => {
        if (!confirmed) return;

        intro();
    }, [confirmed]);

    function intro() {
        addText('output', '||=========================||');
        setTimeout(() => addText('output', '|| Welcome to Takiyo\'s CLI ||'), 100);
        setTimeout(() => addText('output', '|| I told you, not optimized on mobile yet ||'), 200);
        setTimeout(() => addText('output', '|| help for command list!!  ||'), 300);
        setTimeout(() => addText('output', '||=========================||'), 400);
        setTimeout(() => addText('output', ''), 500);
        setTimeout(() => addText('input', ''), 900);
    }

    function onInput(text: string) {
        if (text === "exit") {
            addText('output', 'exit. bye!');
            setTimeout(() => addText('output', '[6962669.696269] Out of memory in UB 963269: OOM Killed process 6962669 (node) total-vm:6962669kB, anon-rss:6962669kB, file-rss:0kB, shmem-rss:0kB'), 100);
            setTimeout(() => addText('output', '.'), 2300);
            setTimeout(() => addText('output', '..'), 2900);
            setTimeout(() => addText('output', 'ay still here?'), 3300);
            setTimeout(() => addText('output', 'just kidding, this is fake CLI so no exit for ya ^-^'), 3700);
            setTimeout(() => {
                setTexts([]);
                intro();
            }, 5500);
            return;
        }
        const answers = getResponse(text);
        for (let i = 0; i < answers.length; i++) addText('output', answers[i]);
        setTimeout(() => addText('input', ''), 300);
    }

    function getResponse(command: string) {
        switch (command) {
            case 'help':
                return [
                    'help - shows this message',
                    'clear - clears the screen',
                    'whoami - shows who is Takiyo. Kinda out of place, but whatever',
                    'projects - shows Takiyo\'s projects',
                    'contact - shows Takiyo\'s contact info',
                    'languages - shows Takiyo\'s programming "languages", not like english, but like programming languages',
                    'tools - shows Takiyo\'s tools',
                    'github - shows Takiyo\'s github',
                    'npm - shows Takiyo\'s npm',
                    'socials - shows Takiyo\'s socials. Actually it\'s the same as contact',
                    'cow - shows a very cute cow',
                    'exit - exits the CLI',
                ]

            case 'clear': {
                setTexts([]);
                return [];
            }

            case 'whoami': {
                return [
                    'Takiyo is a xx year old developer from xxxxx.',
                    'They are a very gud full stack developer, loves both designing sites, making them, and code many backend things.',
                    'They are currently working on a few projects. Actually, many.',
                    'They are still a student, but having fun with programming as a hobby.',
                    'They love doing both digital and traditional art, loves anime so much yeaahh.'
                ]
            }

            case 'projects': {
                return projects.en.map((x, i) => `${i + 1}. ${x.name} - ${stringCropper(x.description, 30)}`);
            }

            case 'contact': {
                return contacts.map((x, i) => `${i + 1}. ${x.name} - ${x.link}`);
            }

            case 'languages': {
                return languages.filter(x => x.type === "language").map((x, i) => `${i + 1}. ${x.name}`);
            }

            case 'tools': {
                return languages.filter(x => x.type === "tool").map((x, i) => `${i + 1}. ${x.name}`);
            }

            case 'github': {
                return ['https://github.com/Takiyo0'];
            }

            case 'npm': {
                return ['https://www.npmjs.com/~takiyo'];
            }

            case 'socials': {
                return contacts.map((x, i) => `${i + 1}. ${x.name} - ${x.link}`);
            }

            case 'cow': {
                return ['img:videos/gifs/cow.gif'];
            }

            case 'exit': {
                return [];
            }

            default: {
                return ['Unknown command. Type help for command list.'];
            }
        }
    }

    function stringCropper(text: string, max: number) {
        return text.length > max ? text.substring(0, max - 3) + '...' : text;
    }

    return (<Box className={"mobile-container"}>
        {confirmed ? <Box className={"main-mobile-view"}>
            <Box>
                {texts.map((text, i) => {
                    if (text.type === 'output') {
                        if (!text.text.startsWith("img:")) return <Text key={i} className={`output-text ${text.text === "System crashed" ? "red" : ""}`}>{text.text}</Text>;
                        else return <img key={i} src={text.text.replace("img:", "")} alt={"response"} className={"output-image"}/>
                    } else {
                        return (<Box className={'input-text'} key={i}>
                            <Text className={'input-text-prompt'}>takiyo@takiyo:~$</Text>
                            <form onSubmit={e => { e.preventDefault(); // @ts-ignore
                                onInput(e.currentTarget[0].value); }
                            }>
                                <input className={'input-text-input'} type="text" disabled={!!text.disabled} autoFocus={!text.disabled}/>
                            </form>
                        </Box>)
                    }
                })}
            </Box>
        </Box> : <Box className={"confirmation"}>
            <Text className={"confirmation-en"}>You are currently viewing this website on a mobile device. This website is not optimized for mobile devices yet. Please view this website on a desktop device for maximum result.</Text>
            <Text className={"confirmation-ja"}>このウェブサイトはまだモバイルデバイスに最適化されていません。最大の結果を得るために、デスクトップデバイスでこのウェブサイトを表示してください。</Text>
            <Box className={"confirmation-button"}>
                <Text className={"confirmation-button-text"} onClick={() => {
                    localStorage.setItem("confirmed", "true");
                    setConfirmed(true);
                }}>Continue / 続行</Text>
            </Box>
        </Box>}
    </Box>)
}