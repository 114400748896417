import chino from "../images/projects/chilledchino.png";
import laffey from "../images/projects/laffey.jpg";
import amagi from "../images/projects/amagi.png";
import bonymous from "../images/projects/bonymous.png";
import chibisafeMobile from "../images/projects/chibisafe-mobile.png";
import kazagumo from "../images/projects/kazagumo.png";
import nagato from "../images/projects/nagato.png";
import montpelier from "../images/projects/montpelier.png";
import webplayer from "../images/projects/webplayer.png";
import sirius from "../images/projects/sirius.png";
import tashkent from "../images/projects/tashkent.jpg";
import webAdmin from "../images/projects/web-admin.png";
import shortener from "../images/projects/shortener.png";

export default {
    en: [
        {
            name: 'ChilledChino',
            description: 'Multipurpose totally free discord bot! Awesome features with 150+ commands!',
            link: 'https://chilledchino.me',
            discontinued: false,
            image: chino
        },
        {
            name: 'Laffey',
            description: 'An open-source discord music bot using Lavalink',
            link: 'https://github.com/Weeb-Devs/Laffey',
            discontinued: false,
            image: laffey
        },
        {
            name: 'Amagi',
            description: 'A module that is built for massive Lavalink searches with built-in rate limit handler.',
            link: 'https://github.com/Takiyo0/Amagi',
            discontinued: false,
            image: amagi
        },
        {
            name: 'Bonymous',
            description: 'A website to collect responses anonymously with board/threading system.',
            link: 'https://github.com/Takiyo0/Bonymous',
            discontinued: false,
            image: bonymous
        },
        {
            name: 'Chibisafe mobile',
            description: 'A mobile client for ChibiSafe instance.',
            link: 'https://github.com/Takiyo0/chibisafe-mobile',
            discontinued: false,
            image: chibisafeMobile
        },
        {
            name: 'Kazagumo',
            description: 'A module that wraps Shoukaku. Simple, built-in queue, and modular.',
            link: 'https://github.com/Takiyo0/Kazagumo',
            discontinued: false,
            image: kazagumo
        },
        {
            name: 'Nagato',
            description: 'An API that handles \'all\' my backends and integrated with discord bot.',
            link: 'https://api.chilledchino.me',
            discontinued: false,
            image: nagato
        },
        {
            name: 'Montpelier',
            description: 'A blog that will post some annoucements and something.',
            link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
            discontinued: true,
            image: montpelier
        },
        {
            name: 'Web player',
            description: 'A web-player. The project is paused right now.',
            link: 'https://player.chilledchino.me',
            image: webplayer
        },
        {
            name: 'Sirius',
            description: 'A spotify clone that is free without ads. Not maintained for now.',
            link: 'https://github.com/ChilledChino/sirius-release',
            discontinued: true,
            image: sirius
        },
        {
            name: 'Tashkent',
            description: 'A restful API for phasmophobia game. The project is paused right now.',
            link: 'https://github.com/Takiyo0/tashkent',
            discontinued: true,
            image: tashkent
        },
        {
            name: 'url shortener',
            description: 'A cool looking (ig) simple url shortener.',
            link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
            discontinued: false,
            image: shortener
        },
        {
            name: 'Blog admin panel',
            description: 'An admin panel to manage posts. This is personal use only',
            link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
            discontinued: true,
            image: webAdmin
        },
    ],
    ja: [
        {
            name: 'ChilledChino',
            description: '多機能な無料のディスコードボット！すばらしい機能と150以上のコマンド！',
            link: 'https://chilledchino.me',
            discontinued: false,
            image: chino
        },
        {
            name: 'Laffey',
            description: 'Lavalinkを使用したオープンソースのディスコードミュージックボット',
            link: 'https://github.com/Weeb-Devs/Laffey',
            discontinued: false,
            image: laffey
        },
        {
            name: 'Amagi',
            description: 'Lavalinkの検索を大規模に行うためのモジュール。内蔵のレートリミットハンドラー付き。',
            link: 'https://github.com/Takiyo0/Amagi',
            discontinued: false,
            image: amagi
        },
        {
            name: 'Bonymous',
            description: '匿名でレスポンスを集めるためのウェブサイト。掲示板/スレッドシステム付き。',
            link: 'https://github.com/Takiyo0/Bonymous',
            discontinued: false,
            image: bonymous
        },
        {
            name: 'Chibisafe mobile',
            description: 'ChibiSafeインスタンスのためのモバイルクライアント。',
            link: 'https://github.com/Takiyo0/chibisafe-mobile',
            discontinued: false,
            image: chibisafeMobile
        },
        {
            name: 'Kazagumo',
            description: 'Shoukakuをラップしたモジュール。シンプルで内蔵のキューとモジュール化。',
            link: 'https://github.com/Takiyo0/Kazagumo',
            discontinued: false,
            image: kazagumo
        },
        {
            name: 'Nagato',
            description: '私のバックエンドを\'すべて\'処理するAPI。ディスコードボットと統合されています。',
            link: 'https://api.chilledchino.me',
            discontinued: false,
            image: nagato
        },
        {
            name: 'Montpelier',
            description: 'アナウンスや何かを投稿するブログ。',
            link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
            discontinued: true,
            image: montpelier
        },
        {
            name: 'Web player',
            description: 'ウェブプレーヤー。現在はプロジェクトが一時停止しています。',
            link: 'https://player.chilledchino.me',
            discontinued: false,
            image: webplayer
        },
        {
            name: 'Sirius',
            description: '広告なしで無料のSpotifyクローン。現在はメンテナンスされていません。',
            link: 'https://github.com/ChilledChino/sirius-release',
            discontinued: true,
            image: sirius
        },
        {
            name: 'Tashkent',
            description: 'PhasmophobiaゲームのためのレストフルAPI。現在はプロジェクトが一時停止しています。',
            link: 'https://github.com/Takiyo0/tashkent',
            discontinued: true,
            image: tashkent
        },
        {
            name: 'url shortener',
            description: 'かっこいい見た目のシンプルなURLショートナー。',
            link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
            discontinued: false,
            image: shortener
        },
        {
            name: 'Blog admin panel',
            description: '投稿を管理する管理パネル。個人的な使用のみ',
            link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
            discontinued: true,
            image: webAdmin
        }
    ]
}