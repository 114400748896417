export default [
        {name: 'JavaScript', type: 'language'},
        {name: 'TypeScript', type: 'language'},
        {name: 'Java', type: 'language'},
        {name: 'C++', type: 'language'},
        {name: 'NodeJS', type: 'language'},
        {name: 'Electron', type: 'language'},
        {name: 'HTML', type: 'language'},
        {name: 'CSS', type: 'language'},
        {name: 'ReactJS', type: 'language'},
        {name: 'ReactTS', type: 'language'},
        {name: 'React Native', type: 'language'},
        {name: 'Linux', type: 'tool'},
        {name: 'MongoDB', type: 'tool'},
        {name: 'NGINX', type: 'tool'},
        {name: 'Azure', type: 'tool'},
        {name: 'Webstorm', type: 'tool'},
        {name: 'CLion', type: 'tool'},
        {name: 'Github', type: 'tool'}
    ]