import {Text, Box, Progress, Image} from "@chakra-ui/react";
import React, {useEffect, Ref, MutableRefObject} from "react";
import {LoadingBarRef} from "react-top-loading-bar";
import LoadingImage from "../images/loading-avatar.gif";
import {TextBox} from "../Elements/Text";
import {TextLink} from "../Elements/Link";
import {TextLogo} from "../Elements/TextLogo";
import {Language, LanguageManager} from "../Languages/Manager";

import uk from "../images/languages/uk.svg";
import ja from "../images/languages/japan.svg";

export function Loading({
                            loading,
                            done,
                            language,
                            setLanguage
                        }: { loading: MutableRefObject<LoadingBarRef | null>, done: () => void, language: Language, setLanguage: React.Dispatch<React.SetStateAction<Language>> }) {
    const lm = LanguageManager({language});
    const [intro, setIntro] = React.useState(false);
    const [doneLoading, setDoneLoading] = React.useState(false);
    const [showText, setShowText] = React.useState(false);
    const [showFooter, setShowFooter] = React.useState(false);
    const [footerLoad, setFooterLoad] = React.useState(false);
    const [showLanguage, setShowLanguage] = React.useState(false);

    useEffect(() => {
        loading.current?.continuousStart();
        const timer = setTimeout(() => {
            setIntro(true);
        }, 200);


        setTimeout(() => finishedLoading(), 3000);

        return () => clearTimeout(timer);
    }, []);

    function finishedLoading() {
        setIntro(false);
        setDoneLoading(true);
        setTimeout(() => done(), 500);
        setTimeout(() => setShowText(true), 1500);
        setTimeout(() => setShowFooter(true), 2000);
        setTimeout(() => setFooterLoad(true), 3200);
        setTimeout(() => setShowLanguage(true), 3500);
        loading.current?.complete();
    }

    const languageList = [
        {name: "English", code: "en", logo: uk},
        {name: "日本語", code: "ja", logo: ja}
    ]

    function setL(language: Language) {
        setDoneLoading(false);
        setShowText(false);
        setShowFooter(false);
        setShowLanguage(false);
        setTimeout(() => setFooterLoad(false), 1800);
        setTimeout(() => setLanguage(language), 2000);
        setTimeout(() => setShowText(true), 3800);
        setTimeout(() => setShowFooter(true), 2800);
        setTimeout(() => setFooterLoad(true), 3900);
        setTimeout(() => setShowLanguage(true), 4200);
        setTimeout(() => setDoneLoading(true), 2500);
    }

    return (
        <Box>
            <Box className={`bar-transition loading-box ${doneLoading ? "loading-box-m" : ""}`}>
                <Box className={`bar-transition avatar-loading-box ${doneLoading ? "avatar-loading-box-m" : ""}`}>
                    <Image src={LoadingImage}
                           className={`bar-transition loading-box-avatar ${intro ? "flydown-2" : ""} ${doneLoading ? "loading-box-avatar-m" : ""}`}/>
                </Box>
                <TextBox text={"たきよ"} show={showText} customParentClass={"loading-text"}
                         customTextClass={"loading-text-t"}/>
                <Box className={`short-transition language-selector ${showLanguage ? "language-selector-show" : "language-selector-hide"}`}>
                    {languageList.sort((a, b) => a.code === language ? -1 : b.code === language ? 1 : 0).map((l, i) => (
                        <Box key={i} className={"short-transition language-selector-child"}
                             onClick={() => i === 0 ? void 0 : setL(l.code as Language)}>
                            <Image src={l.logo} alt={l.name}/>
                            <Text>{l.name}</Text>
                        </Box>))}
                </Box>
            </Box>

            <Box className={`transition loading-footer loading-footer-1 ${showFooter ? "loading-footer-s" : ""}`}/>
            <Box className={`bar-transition loading-footer ${showFooter ? "loading-footer-s" : ""}`}>
                <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                     viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <defs>
                        <path id="gentle-wave"
                              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"/>
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"/>
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"/>
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff"/>
                    </g>
                </svg>
                {footerLoad ? <Box className={"footer-content"}>
                    <TextLogo topText={lm.words.footer.takiyos} bottomText={lm.words.footer.portfolio}/>
                    <Box className={"footer-content-link"}>
                        <TextLink text={"Github"} link={"https://github.com/Takiyo0"}/>
                        <TextLink text={"Discord"} link={"https://discordapp.com/users/478881452604522499"}/>
                        <TextLink text={"Twitter"} link={"https://twitter.com/Takiyo0"}/>
                        <TextLink text={"Patreon"} link={"https://www.patreon.com/takiyo"}/>
                    </Box>
                </Box> : null}
            </Box>
        </Box>
    );
}