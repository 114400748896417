import * as React from "react"
import "./css/main.css";
import "./css/home.css";
import "./css/Text.css";
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid,
    extendTheme,
    ThemeConfig
} from "@chakra-ui/react";
import {Loading} from "./Pages/Loading";
import {useEffect, useRef} from "react";
import LoadingBar from "react-top-loading-bar";
import {Home} from "./Pages/Home";
import {Language} from "./Languages/Manager";

export function App() {
    const ref = useRef(null);
    const [showHome, setShowHome] = React.useState(false);
    const [language, setLanguage] = React.useState<Language>(localStorage.getItem("language") as Language || "en");
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const firstInitRef = useRef(true);

    useEffect(() => {
        console.log(`setting language to ${language}`);
        localStorage.setItem("language", language);

        if (firstInitRef.current) firstInitRef.current = false;
        else {
            setShowHome(false);
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => setShowHome(true), 500);
        }
    }, [language]);

    function doneLoading() {
        setShowHome(true);
    }

    // 2. Add your color mode config
    const config: ThemeConfig = {
        initialColorMode: 'light',
        useSystemColorMode: false
    }

// 3. extend the theme
    const theme = extendTheme({config, colors: {color: '#FFFFFF'}})

    return (
        <ChakraProvider theme={theme}>
            <Box style={{position: 'absolute', left: 0, right: 0, bottom: 0}} className="loading-bar-container">
                <LoadingBar color='#f11946' ref={ref} shadowStyle={{display: 'none'}}/>
            </Box>
            <Box textAlign="center" fontSize="xl" style={{overflow: "hidden"}}>
                <Grid minH="100vh" minW="100vw">
                    <Loading key="loading" loading={ref} done={doneLoading} language={language} setLanguage={setLanguage}/>
                    {showHome ? <Box className={"home-big-container-owo"}>
                        <Box className={"home-big-container"}>
                            <Home key="home" loading={ref} language={language}/>
                        </Box>
                    </Box> : null}
                </Grid>
            </Box>
        </ChakraProvider>
    )
}