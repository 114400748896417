import React from "react";

import en from "./EN";
import ja from "./JA";

function getName(language: Language) {
    if (language === "en") return "English";
    if (language === "ja") return "日本語";
    return "Unknown";
}

function getWords(language: Language) {
    if (language === "en") return en;
    if (language === "ja") return ja;
    return en;
}

export function LanguageManager({language}: { language: Language }) {
    const [currentLanguage, setCurrentLanguage] = React.useState<Language>(language ?? 'en');
    const [currentLanguageName, setCurrentLanguageName] = React.useState<string>(getName(language ?? 'en'));
    const [currentLanguageCode, setCurrentLanguageCode] = React.useState<string>(language ?? 'en');

    const [words, setWords] = React.useState(getWords(language ?? 'en'));

    React.useEffect(() => {
        setLanguage(language ?? 'en');
        console.log(`language manager called. changing to ${language}`);
    }, [language]);

    function setLanguage(language: Language) {
        if (!["en", "ja"].includes(language)) language = 'en';
        setCurrentLanguage(language);
        setCurrentLanguageName(getName(language));
        setCurrentLanguageCode(language);
        setWords(getWords(language));
    }

    return {
        language: currentLanguage,
        languageName: currentLanguageName,
        languageCode: currentLanguageCode,
        words: words,
        setLanguage:(language: Language) => setLanguage(language)
    };

}

export interface LanguageManagerRef {
    getLanguage: () => Language;
    getLanguageName: () => string;
    getLanguageCode: () => string;
    setLanguage: (language: 'en' | 'ja') => void;
}

export type Language = 'en' | 'ja';