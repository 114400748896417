export default {
    words: {
        introduction: "Introduction",
        aboutMe: "About Me",
        aboutSMe: "ABOUT \n ME",
        languages: "Languages",
        languagesSTools: "LANGUAGES \n TOOLS",
        projects: "Projects",
        contact: "Contact",
        maintenance: "Maintenance",
        keepScrolling: "Keep Scrolling",
    },
    page1: {
        im: "I'm",
        takiyo: "Takiyo",
        desu: "",
        fullStackDeveloper: "Full Stack Developer",
        aboutMe: "I'm a {numGlitch} year old developer from {strGlitch}. Right now I'm a student and I need to split my time for both study and programming. I'm a self-taught developer. I started programming when I was {numGlitch} years old. I started my programming journey with JavaScript discord music bot and now it continues until now."
    },
    page2: {
        javascript: "JavaScript is my first language",
        typescript: "I love TypeScript",
        java: "I know Java",
        cpp: "I learn C++",
        nodejs: "I use this for my backend",
        electron: "I use this for my desktop apps",
        html: "\"Language\" yes, I love web development",
        css: "\"Language\" yes, I love web development",
        reactjs: "I use this for my frontend",
        reactts: "I use this for my frontend too, but in TS",
        reactNative: "I use this for my mobile apps",
        linux: "I use this for my servers",
        mongodb: "I use this for my databases",
        nginx: "I use this for my servers (proxy)",
        azure: "My VPS",
        webstorm: "My IDE",
        clion: "My IDE",
        github: "My code repository"
    },
    footer: {
        takiyos: "TAKIYO'S",
        portfolio: "PORTFOLIO",
        twitter: "Twitter",
        github: "Github",
        discord: "Discord",
        patreon: "Patreon"
    }
}