import React from "react";
import {Language} from "../Languages/Manager";

export function GlitchText({length, type, language}: { length: number, type: 'string' | 'number' | 'random', language?: Language }) {
    const [text, setText] = React.useState<string>("");

    const interval = React.useRef<NodeJS.Timeout | undefined>();

    const japaneseWords = ['あ', 'い', 'う', 'え', 'お', 'か', 'き', 'く', 'け', 'こ', 'さ', 'し', 'す', 'せ', 'そ', 'た', 'ち', 'つ', 'て', 'と', 'な', 'に', 'ぬ', 'ね', 'の', 'は', 'ひ', 'ふ', 'へ', 'ほ', 'ま', 'み', 'む', 'め', 'も', 'や', 'ゆ', 'よ', 'ら', 'り', 'る', 'れ', 'ろ', 'わ', 'を', 'ん', 'が', 'ぎ', 'ぐ', 'げ', 'ご', 'ざ', 'じ', 'ず', 'ぜ', 'ぞ', 'だ', 'ぢ', 'づ', 'で', 'ど', 'ば', 'び', 'ぶ', 'べ', 'ぼ', 'ぱ', 'ぴ', 'ぷ', 'ぺ', 'ぽ', 'ぁ', 'ぃ', 'ぅ', 'ぇ', 'ぉ', 'っ', 'ゃ', 'ゅ', 'ょ', 'ゎ', 'ゕ', 'ゖ', 'ゔ', 'ゝ', 'ゞ', 'ァ', 'ィ', 'ゥ', 'ェ', 'ォ', 'ッ', 'ャ', 'ュ', 'ョ', 'ヮ', 'ヵ', 'ヶ', 'ヴ', 'ヽ', 'ヾ', 'ア', 'イ', 'ウ', 'エ', 'オ', 'カ', 'キ', 'ク', 'ケ', 'コ', 'サ', 'シ', 'ス', 'セ', 'ソ', 'タ', 'チ', 'ツ', 'テ', 'ト', 'ナ', 'ニ', 'ヌ', 'ネ', 'ノ', 'ハ', 'ヒ', 'フ', 'ヘ', 'ホ', 'マ', 'ミ', 'ム', 'メ', 'モ', 'ヤ', 'ユ', 'ヨ', 'ラ', 'リ', 'ル', 'レ', 'ロ', 'ワ', ''];

    React.useEffect(() => {
        interval.current = setInterval(() => {
            let newText = "";
            for (let i = 0; i < length; i++) {
                if (type === 'string') {
                    // newText += String.fromCharCode(Math.floor(Math.random() * 26) + 97);
                    if (language === 'en') newText += String.fromCharCode(Math.floor(Math.random() * 26) + 97);
                    if (language === 'ja') newText += japaneseWords[Math.floor(Math.random() * japaneseWords.length)];
                } else if (type === 'number') {
                    newText += Math.floor(Math.random() * 10);
                } else {
                    newText += Math.random() > 0.5 ? String.fromCharCode(Math.floor(Math.random() * 26) + 97) : Math.floor(Math.random() * 10);
                }
            }
            setText(newText);
        }, 100);

        return () => clearInterval(interval.current);
    }, []);

    return <span>{text}</span>;
}