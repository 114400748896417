import React from "react";
import {Box, Text} from "@chakra-ui/react";
import "../css/TextLogo.css";

export function TextLogo({topText, bottomText}: { topText: string, bottomText: string }) {
    const [t1, setT1] = React.useState<"c" | "o">("c");
    const [t2, setT2] = React.useState<"c" | "o">("c");

    const [bottomAppear, setBottomAppear] = React.useState(false);

    React.useEffect(() => {
        const t1 = setTimeout(() => setT1("o"), 100);
        const t2 = setTimeout(() => setT2("o"), 200);
        const t3 = setTimeout(() => setBottomAppear(true), 300);

        return () => {
            [t1, t2, t3].map(t => clearTimeout(t));
        }
    }, []);


    return (<Box className="text-logo-container">
        <Box className={`text-logo-container-top`}>
            <Box
                className={`bar-transition text-logo-box text-logo-box-top ${t1 === "c" ? "text-logo-box-top-active" : ""}`}/>
            <Text className={`text-logo-top`}>{topText}</Text>
            <Box
                className={`bar-transition text-logo-box text-logo-box-bottom ${t2 === "c" ? "text-logo-box-top-active" : ""}`}/>
        </Box>
        <Text className={"text-logo-bottom"}>{bottomText}</Text>
    </Box>)
}