import React, {MutableRefObject, UIEventHandler, useEffect, useRef, useState} from "react";
import {LoadingBarRef} from "react-top-loading-bar";
import {Box, Image, Text, Tooltip} from "@chakra-ui/react";
import reactStringReplace from 'react-string-replace';

import Element1 from "../images/element-1.svg";
import JavascriptLogo from "../images/logo/javascript.png";
import CSSLogo from "../images/logo/css.png";
import HTMLLogo from "../images/logo/html.png";
import LinuxLogo from "../images/logo/linux.png";
import ReactLogo from "../images/logo/react.png";
import TypescriptLogo from "../images/logo/typescript.png";
import NodeLogo from "../images/logo/nodejs.png";
import MongoDBLogo from "../images/logo/mongodb.png";
import ClionLogo from "../images/logo/clion.png";
import CPPLogo from "../images/logo/cpp.png";
import WebstormLogo from "../images/logo/webstorm.png";
import GithubLogo from "../images/logo/github.png";
import NGINXLogo from "../images/logo/nginx.png";
import AzureLogo from "../images/logo/azure.png";
import JavaLogo from "../images/logo/java.png";
import ElectronLogo from "../images/logo/electron.png";
import {TextBox} from "../Elements/Text";
import {GlitchText} from "../Elements/GlitchText";

import projects from "../data/projects";
import {Language, LanguageManager} from "../Languages/Manager";


export function Home({loading, language}: { loading: MutableRefObject<LoadingBarRef | null>, language: Language }) {
    const lm = LanguageManager({language});
    const [show, setShow] = React.useState(false);
    const [lockScroll, setLockScroll] = React.useState(true);
    const [page1, setPage1] = React.useState({
        content1: false,
        avatar: false,
        text1: false,
        text2: false,
        video1: false,
        aboutMe: false,
        appearAboutMe: false
    });
    const [page2, setPage2] = React.useState({
        content1: false,
        video1: false,
        text1: false,
        languages: false
    });
    const [page3, setPage3] = React.useState({
        content1: false,
        video1: false,
        projects: false
    });
    const [page4, setPage4] = React.useState({
        content1: false,
        video1: false
    });
    const [scrollElement, setScrollElement] = React.useState<{ state: 'none' | 'full' | 'minimized', percent: number, page: number }>({
        state: 'none',
        percent: 0,
        page: 1
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [title, setTitle] = useState(lm.words.words.introduction.toUpperCase());
    const titleRef = useRef(lm.words.words.introduction.toUpperCase());

    const [nextTitle, setNextTitle] = useState(lm.words.words.aboutMe.toUpperCase());
    const nextTitleRef = useRef(lm.words.words.aboutMe.toUpperCase());
    const [startChangingTitle, setStartChangingTitle] = useState(false);
    const [pageAnimationType, setPageAnimationType] = useState<"next" | "previous">("next");
    const [pageRotateType, setPageRotateType] = useState<"normal" | "rotate">("normal");
    const [pageMainRotateType, setPageMainRotateType] = useState<"normal" | "rotate">("normal");

    const [nextPage, setNextPage] = useState(2);
    const [currentPagePage, setCurrentPagePage] = useState(1);
    const nextPageRef = useRef(2);
    const [startChangingPage, setStartChangingPage] = useState(false);
    const [pageNumberAnimationType, setPageNumberAnimationType] = useState<"next" | "previous">("next");

    const [rightBarText, setRightBarText] = useState({show: false, text: lm.words.words.introduction.toUpperCase()});
    const rightBarTextRef = useRef(lm.words.words.introduction.toUpperCase());

    const scrollTimeout = useRef<NodeJS.Timeout | undefined>();
    const scrollMTimeout = useRef<NodeJS.Timeout | undefined>();
    const scrollFullDone = useRef<Boolean>(false);
    const rightBarBackTextTimeout = useRef<NodeJS.Timeout[]>([]);
    const pageTimeouts = useRef<NodeJS.Timeout[]>([]);
    const changingTitleTimeout = useRef<NodeJS.Timeout | undefined>();
    const currentPageRef = useRef(1);
    const initialLoad = useRef(true);
    const lockRef = useRef<NodeJS.Timeout>(setTimeout(() => void 0, 0));

    const [hide, setHide] = useState({
        page1: false,
        page2: true,
        page3: true
    });
    const hideTimeouts = useRef<NodeJS.Timeout[]>([]);

    React.useEffect(() => {
        setTimeout(() => setShow(true), 1000);

        window.addEventListener("scroll", handleScroll);

        return () => {
            if (scrollMTimeout.current) clearTimeout(scrollMTimeout.current);
            if (scrollTimeout.current) clearTimeout(scrollTimeout.current);
            window.removeEventListener("scroll", handleScroll);
        }

    }, []);

    React.useEffect(() => lm.setLanguage(language), [language]);

    useEffect(() => {
        const prevPage = currentPageRef.current;
        currentPageRef.current = currentPage;

        switch (currentPage) {
            case 1: {
                handlePage1(true, prevPage);
                break;
            }

            case 2: {
                handlePage2(true, prevPage);
                break;
            }

            case 3: {
                handlePage3(true, prevPage);
                break;
            }

            case 4: {
                handlePage4(true, prevPage);
                break;
            }
        }
    }, [currentPage]);

    const pages = [
        document.getElementById("page-1"),
        document.getElementById("page-2")
    ]

    function barHandler({
                            left,
                            right
                        }: { left?: { show: boolean, width?: string, rotation?: number }, right?: { show: boolean, width?: string, rotation?: number } }) {
        if (left && left.show && !!left.width && !isNaN(left.rotation!)) {
            document.documentElement.style.setProperty("--left-bar-width", left.width!);
            document.documentElement.style.setProperty("--left-bar-rotation", left.rotation! + "deg");
        } else {
            document.documentElement.style.setProperty("--left-bar-width", "0px");
            document.documentElement.style.setProperty("--left-bar-rotation", "0deg");
        }

        if (right && right.show && !!right.width && !isNaN(right.rotation!)) {
            document.documentElement.style.setProperty("--right-bar-width", right.width!);
            document.documentElement.style.setProperty("--right-bar-rotation", right.rotation! + "deg");
        } else {
            document.documentElement.style.setProperty("--right-bar-width", "0px");
            document.documentElement.style.setProperty("--right-bar-rotation", "0deg");
        }
    }

    function rightBarTextHandler(text?: string) {
        rightBarBackTextTimeout.current.map(t => clearTimeout(t));

        if (!text) return setRightBarText({show: false, text: ''});

        setRightBarText({show: false, text: rightBarTextRef.current});
        rightBarTextRef.current = text;
        const t1 = setTimeout(() => setRightBarText({show: true, text: text}), 1500);

        rightBarBackTextTimeout.current.push(t1);
    }

    function changeTitle(text: string, prevPage: number, rotate: boolean = false) {
        const isItNext = prevPage < currentPage;
        setPageAnimationType(isItNext ? "next" : "previous");

        if (changingTitleTimeout.current) clearTimeout(changingTitleTimeout.current);

        if (rotate) setPageRotateType("rotate");
        else setPageRotateType("normal");

        setNT(text);
        setStartChangingTitle(true);
        const transitionDelay = 2000;
        changingTitleTimeout.current = setTimeout(() => {
            setTitle(text);
            if (rotate) setPageMainRotateType("rotate");
            else setPageMainRotateType("normal");
            setStartChangingTitle(false);
        }, transitionDelay);
    }

    function changePage(page: number, prevPage: number) {
        const isItNext = prevPage < page;
        setPageNumberAnimationType(isItNext ? "next" : "previous");
        setStartChangingPage(true);
        setNextPage(page);
        const transitionDelay = 2000;
        setTimeout(() => {
            setCurrentPagePage(page);
            setStartChangingPage(false);
        }, transitionDelay);
    }

    function setT(t: string) {
        setTitle(t);
        titleRef.current = t;
    }

    function setNT(t: string) {
        setNextTitle(t);
        nextTitleRef.current = t;
    }

    function showHandler(page: number) {
        hideTimeouts.current.map(t => clearTimeout(t));

        switch (page) {
            case 1: {
                setHide(d => ({...d, page1: false}));
                hideTimeouts.current.push(setTimeout(() => setHide(d => ({...d, page2: true})), 1700));
                break;
            }

            case 2: {
                setHide(d => ({...d, page2: false}));
                hideTimeouts.current.push(setTimeout(() => setHide(d => ({...d, page3: true, page1: true})), 1700));
                break;
            }

            case 3: {
                setHide(d => ({...d, page3: false}));
                hideTimeouts.current.push(setTimeout(() => setHide(d => ({...d, page2: true})), 1700));
                break;
            }
        }
    }

    function lock() {
        clearTimeout(lockRef.current);
        setLockScroll(true);
        lockRef.current = setTimeout(() => setLockScroll(false), 2000);
    }

    useEffect(() => {
        if (lockScroll) document.getElementsByTagName("body")[0].style.overflow = "hidden";
        else document.getElementsByTagName("body")[0].style.overflow = "auto";
    }, [lockScroll]);

    function handlePage1(show: boolean, prevPage: number) {
        if (show) {
            handlePage2(false, prevPage);
            handlePage3(false, prevPage);
            changePage(1, prevPage);
            showHandler(1);
            lock();

            barHandler({
                left: {show: true, width: '350px', rotation: 13},
                right: {show: true, width: 'clamp(470px, 40vw, 570px)', rotation: 13}
            });
            const transitionDelay = initialLoad.current ? {
                avatar: 500,
                text1: 1000,
                text2: 1500,
                video1: 100,
                content1: 200,
                aboutMe: 500
            } : {
                avatar: 1300,
                text1: 1800,
                text2: 2300,
                video1: 600,
                content1: 1000,
                aboutMe: 1300
            }

            initialLoad.current = false;

            changeTitle(lm.words.words.aboutMe.toUpperCase(), prevPage);
            rightBarTextHandler(lm.words.words.aboutSMe);
            setRightBarColor('normal');
            const t8 = setTimeout(() => setPage1({...page1, video1: true}), transitionDelay.video1);
            const t4 = setTimeout(() => setPage1((prev) => ({...prev, content1: true})), transitionDelay.content1);
            const t5 = setTimeout(() => setPage1((prev) => ({...prev, avatar: true})), transitionDelay.avatar);
            const t6 = setTimeout(() => setPage1((prev) => ({...prev, text1: true})), transitionDelay.text1);
            const t7 = setTimeout(() => setPage1((prev) => ({...prev, text2: true})), transitionDelay.text2);
            const t3 = setTimeout(() => setPage1((prev) => ({...prev, appearAboutMe: true})), 1700);


            pageTimeouts.current.push(t4, t5, t6, t7, t3);

        } else {
            pageTimeouts.current.forEach((t) => clearTimeout(t));
            setPage1((d) => ({
                avatar: false,
                text1: false,
                text2: false,
                video1: false,
                content1: false,
                aboutMe: d.aboutMe,
                appearAboutMe: false
            }));
        }
    }

    function handlePage2(show: boolean, prevPage: number) {
        if (show) {
            handlePage1(false, prevPage);
            handlePage3(false, prevPage);
            showHandler(2);
            changePage(2, prevPage);
            lock();

            changeTitle(lm.words.words.languages.toUpperCase(), prevPage, true);
            rightBarTextHandler(lm.words.words.languagesSTools);
            setRightBarColor('normal');
            barHandler({
                left: {show: true, width: '350px', rotation: -12},
                right: {show: true, width: 'clamp(470px, 40vw, 570px)', rotation: -12}
            });
            const t1 = setTimeout(() => setPage2((prev) => ({...prev, video1: true})), 300);
            const t5 = setTimeout(() => setPage2((prev) => ({...prev, content1: true})), 1800);
            const t6 = setTimeout(() => setPage2((prev) => ({...prev, text1: true})), 2500);
            const t7 = setTimeout(() => setPage2((prev) => ({...prev, languages: true})), 3000);

            pageTimeouts.current.push(t1, t5, t6, t7);

        } else {
            pageTimeouts.current.forEach((t) => clearTimeout(t));
            setPage2({
                content1: false,
                video1: false,
                text1: false,
                languages: false
            });
        }
    }

    function handlePage3(show: boolean, prevPage: number) {
        if (show) {
            handlePage2(false, prevPage);
            handlePage4(false, prevPage);
            showHandler(3);
            changePage(3, prevPage);
            lock();

            changeTitle(lm.words.words.projects.toUpperCase(), prevPage);
            rightBarTextHandler(lm.words.words.projects.toUpperCase());
            setRightBarColor('semi-transparent');
            barHandler({
                left: {show: true, width: '350px', rotation: 0},
                right: {show: true, width: 'clamp(500px, 40vw, 570px)', rotation: 0}
            });
            const t1 = setTimeout(() => setPage3((prev) => ({...prev, video1: true})), 300);
            const t2 = setTimeout(() => setPage3((prev) => ({...prev, content1: true})), 600);
            const t3 = setTimeout(() => setPage3((prev) => ({...prev, projects: true})), 1500);

            pageTimeouts.current.push(t1, t2, t3);
        } else {
            pageTimeouts.current.forEach((t) => clearTimeout(t));
            setPage3({
                content1: false,
                video1: false,
                projects: false
            });
        }
    }

    function handlePage4(show: boolean, prevPage: number) {
        if (show) {
            handlePage3(false, prevPage);
            showHandler(4);
            changePage(4, prevPage);
            lock();

            changeTitle(lm.words.words.maintenance.toUpperCase(), prevPage);
            rightBarTextHandler(lm.words.words.maintenance.toUpperCase());
            setRightBarColor('normal');
            barHandler({
                left: {show: true, width: '350px', rotation: 15},
                right: {show: true, width: 'clamp(500px, 40vw, 570px)', rotation: 15}
            });
            const t1 = setTimeout(() => setPage4((prev) => ({...prev, video1: true})), 300);
            const t2 = setTimeout(() => setPage4((prev) => ({...prev, content1: true})), 600);

            pageTimeouts.current.push(t1, t2);
        } else {
            pageTimeouts.current.forEach((t) => clearTimeout(t));
            setPage4({
                content1: false,
                video1: false
            });
        }
    }

    function setRightBarColor(type: 'normal' | 'semi-transparent' | 'transparent') {
        document.documentElement.style.setProperty('--right-bar-color', type === 'normal' ? 'rgba(208, 208, 208, 0.8)' : type === 'semi-transparent' ? 'rgba(208, 208, 208, 0.2)' : 'rgba(208, 208, 208, 0)');
        document.documentElement.style.setProperty('--right-bar-color-1', type === 'normal' ? 'rgb(170 170 170)' : type === 'semi-transparent' ? 'rgba(170, 170, 170, 0.2)' : 'rgba(170, 170, 170, 0)');
    }

    function handleScroll(this: Window) {
        const position = document.documentElement.scrollTop || document.body.scrollTop;
        const viewHeight = document.documentElement.clientHeight;
        const currentPage = Math.floor(position / viewHeight) + 1;
        const percentOnPage = (position % viewHeight) / viewHeight;
        const parsedPercent = Math.floor(percentOnPage * 100);

        scrollHandler(currentPage, parsedPercent)
    }

    useEffect(() => {
        if (scrollElement.state === "full" && !scrollTimeout.current) {
            const body = document.body;
            body.style.overflow = "hidden";
            scrollTimeout.current = setTimeout(() => {
                setScrollElement((d) => ({...d, state: "minimized"}));
                scrollFullDone.current = true;
                scrollTimeout.current = undefined;
                body.style.overflow = "auto";
            }, 1400);
        }

        if (scrollElement.state === "minimized") {
            if (scrollMTimeout.current) clearTimeout(scrollMTimeout.current);

            scrollMTimeout.current = setTimeout(() => {
                setScrollElement((d) => ({...d, state: "none"}));
                scrollMTimeout.current = undefined;
            }, 3000);
        }
    }, [scrollElement]);

    function scrollHandler(curPage: number, perPassed: number) {
        let currentPage = curPage,
            percentPassed = perPassed,
            nextPage = currentPage + 1;

        if (currentPageRef.current !== currentPage) {
            setCurrentPage(currentPage);
        }

        setScrollElement((d) => {
            if (d.state === "none") {
                return {
                    state: "full",
                    percent: percentPassed,
                    page: currentPage
                }
            }

            return {
                state: d.state,
                percent: percentPassed,
                page: currentPage
            }
        });
    }

    function getState(state: 'none' | 'full' | 'minimized') {
        if (scrollFullDone.current && state === "full") state = "minimized";

        switch (state) {
            case "none":
                return "scroll-page";
            case "full":
                return "scroll-page scroll-page-full";
            case "minimized":
                return "scroll-page scroll-page-minimized";
        }
    }

    return (
        <Box className={`homepage`}>
            <Box className={`transition ${getState(scrollElement.state)}`}>
                <Text className={'transition'}>{lm.words.words.keepScrolling}</Text>
                <Box className={`transition scroll-page-progress-bar-container`}>
                    <Box className={`scroll-page-progress-bar`}
                         style={{width: `${scrollElement.percent}%`}}/>
                </Box>
            </Box>
            <Box id="page-1" className={`page-container`}>
                <Box className="home-page-container">
                    {/* THIS THING FOR LEFT BAR */}
                    <Box className={`bar-transition square-rotated`}></Box>
                    <Box className={`bar-transition square-rotated-1`}>
                        <Box className={`square-rotated-1-light-bar`}/>
                        <Text
                            className={`rotate-bar-transition square-rotated-1-page-3 ${pageNumberAnimationType === "previous" && startChangingPage ? `page-transition square-rotated-1-page-3-change` : ""}`}>{nextPage}</Text>
                        <Text
                            className={`rotate-bar-transition square-rotated-1-page-1 ${startChangingPage ? `page-transition square-rotated-1-page-1-${pageNumberAnimationType === "previous" ? "previous" : "next"}` : ""}`}>{currentPagePage}</Text>
                        <Text
                            className={`rotate-bar-transition square-rotated-1-page-2 ${pageNumberAnimationType === "next" && startChangingPage ? `page-transition square-rotated-1-page-2-change` : ""}`}>{nextPage}</Text>
                        <Text
                            className={`square-rotated-1-text-3 ${pageAnimationType === "previous" && startChangingTitle ? `text-transition ${pageRotateType === "rotate" ? "square-rotated-1-text-3-change-rotated" : "square-rotated-1-text-3-change"}` : ""}`}>{nextTitle}</Text>
                        <Text
                            className={`square-rotated-1-text ${pageMainRotateType === "rotate" ? "square-rotated-1-text-change-rotated" : ""} ${startChangingTitle ? `text-transition ${pageAnimationType === "previous" ? "square-rotated-1-text-change-bottom" : "square-rotated-1-text-change-top"}` : ""}`}>{title}</Text>
                        <Text
                            className={`square-rotated-1-text-2 ${pageAnimationType === "next" && startChangingTitle ? `text-transition ${pageRotateType === "rotate" ? "square-rotated-1-text-2-change-rotated" : "square-rotated-1-text-2-change"}` : ""}`}>{nextTitle}</Text>
                    </Box>

                    {/* THIS THING FOR RIGHT BAR */}
                    <Box className={`bar-transition r-square-rotated`}></Box>
                    <Box className={`bar-transition r-square-rotated-1`}>
                        <TextBox text={rightBarText.text} show={rightBarText.show}
                                 customTextClass={`r-square-rotated-1-back-text`}
                                 customParentClass="bar-transition r-square-rotated-1-back"
                                 blackHex={"#d0d0d0"}/>
                        <TextBox text={rightBarText.text} show={rightBarText.show}
                                 customTextClass={`r-square-rotated-1-back-text-2`}
                                 customParentClass="bar-transition r-square-rotated-1-back bar-transition r-square-rotated-1-back-2"
                                 blackHex={"#d0d0d0"}/>
                    </Box>

                    {/* PAGE 1 RIGHT CONTENT */}
                    <Box
                        className={`bar-transition square-content ${page1.content1 ? "square-content-show" : "square-content-hide"} ${hide.page1 ? "display-none" : ""}`}>
                        <Box className={`transition square-content-container`}>
                            <img
                                className={`transition square-content-avatar ${page1.avatar ? "flydown-2" : "flydown-1"}`}
                                src="https://cdn.discordapp.com/avatars/478881452604522499/9e98bb6f89efcf224cd58897e9b61d4a.png?size=4096"
                                alt="avatar"/>
                            <Box
                                className={`transition square-content-text ${page1.text1 ? "flyright-1" : "flyright-2"}`}>{lm.words.page1.im}
                                <Text className={`glitch2 square-content-text-glitch`}>
                                    <span aria-hidden={true}>{lm.words.page1.takiyo}</span>
                                    {lm.words.page1.takiyo}
                                    <span aria-hidden={true}>{lm.words.page1.takiyo}</span>
                                </Text>{lm.words.page1.desu}</Box>
                            <Box
                                className={`transition square-content-text square-content-text-n ${page1.text2 ? "flyup-1" : "flyup-2"}`}>{lm.words.page1.fullStackDeveloper}</Box>
                        </Box>
                    </Box>


                    <Box
                        className={`transition square-content-aboutme-container ${page1.appearAboutMe ? "flyup-1-m" : "flyup-2-m"} ${hide.page1 ? "display-none" : ""}`}>
                        <Box
                            className={`transition square-content-aboutme ${page1.aboutMe ? "aboutme-expand" : "aboutme-collapse"}`}>
                            <Text onClick={() => setPage1((d) => ({...d, aboutMe: !d.aboutMe}))}
                                  className={`square-content-aboutme-text`}>{lm.words.words.aboutMe}</Text>
                            <Box
                                className={`transition square-content-aboutme-text-2`}>
                                {/*<Text className={`transition square-content-aboutme-text-2-text`}>{language.words.page1.aboutMe.replace('{strGlitch}', (<GlitchText length={2} type={'number'}/>))}</Text>*/}
                                <Text className={`transition square-content-aboutme-text-2-text`}>{(() => {
                                    let str = lm.words.page1.aboutMe;
                                    let replaced;
                                    let strGlitch = (<GlitchText length={2} type={'string'} language={lm.language}/>);
                                    let numGlitch = <GlitchText length={2} type={'number'} language={lm.language}/>;
                                    let randomGlitch = <GlitchText length={5} type={'random'} language={lm.language}/>;

                                    replaced = reactStringReplace(str, '{strGlitch}', () => strGlitch);
                                    replaced = reactStringReplace(replaced, '{numGlitch}', () => numGlitch);
                                    replaced = reactStringReplace(replaced, '{randomGlitch}', () => randomGlitch);

                                    return replaced;
                                })()}</Text>
                            </Box>
                        </Box>
                    </Box>

                    {/*PAGE 2 RIGHT CONTENT*/}
                    <Box
                        className={`transition page2-square-content-languages  ${page2.languages ? "flydown-1-m" : "flydown-2-m"} ${hide.page2 ? "display-none" : ""}`}>
                        {[
                            [JavascriptLogo, "JavaScript", false, lm.words.page2.javascript],
                            [TypescriptLogo, "TypeScript", false, lm.words.page2.typescript],
                            [JavaLogo, "Java", false, lm.words.page2.java],
                            [CPPLogo, "C++", false, lm.words.page2.cpp],
                            [NodeLogo, "NodeJS", false, lm.words.page2.nodejs],
                            [ElectronLogo, "Electron", false, lm.words.page2.electron],
                            [HTMLLogo, "HTML", false, lm.words.page2.html],
                            [CSSLogo, "CSS", false, lm.words.page2.css],
                            [ReactLogo, "ReactJS", false, lm.words.page2.reactjs],
                            [ReactLogo, "ReactTS", false, lm.words.page2.reactts],
                            [ReactLogo, "React Native", false, lm.words.page2.reactNative],
                            [LinuxLogo, "Linux", false, lm.words.page2.linux],
                            [MongoDBLogo, "MongoDB", false, lm.words.page2.mongodb],
                            [NGINXLogo, "NGINX", false, lm.words.page2.nginx],
                            [AzureLogo, "Azure", false, lm.words.page2.azure],
                            [WebstormLogo, "Webstorm", false, lm.words.page2.webstorm],
                            [ClionLogo, "CLion", true, lm.words.page2.clion],
                            [GithubLogo, "Github", false, lm.words.page2.github],
                        ].map(([logo, name, invert, tooltip]: any, i) => (
                            <Tooltip label={tooltip} key={i} placement="top">
                                <Box className={`transition page2-square-content-language unselectable`} key={i}
                                     style={{
                                         backgroundImage: `url(${Element1})`,
                                         animation: `hueRotate ${Math.floor(Math.random() * 15) + 5}s infinite`
                                     }}>
                                    <Image src={logo} alt={`${name} Logo`} className={`${invert ? "invert" : ""}`}/>
                                    <Box>
                                        <Text className={"page2-square-content-language-text"}>{name}</Text>
                                    </Box>
                                </Box>
                            </Tooltip>
                        ))}
                    </Box>

                    <Box
                        className={`bar-transition page3-content ${page3.content1 ? "page3-content-show" : "page3-content-hide"} ${hide.page3 ? "display-none" : ""}`}>
                        {projects[language].map((data, i) => (<Box
                            className={`transition page3-content-project ${page3.projects ? "flyup-1-m" : "flyup-2-m"}`}
                            key={i} onClick={() => window.open(data.link, "_blank")}>
                            <Box className={`normal-transition page3-content-project-image`}
                                 style={{backgroundImage: `url(${data.image})`}}/>
                            <Box className={`transition page3-content-project-content`}>
                                <Box>
                                    <Text className={'short-transition page3-content-project-name'}>{data.name}</Text>
                                    <Text
                                        className={'short-transition page3-content-project-description'}>{data.description}</Text>
                                </Box>
                            </Box>
                        </Box>))}
                    </Box>

                    <Box className="home-page-video-background">
                        <video
                            className={`home-page-video video-transition ${page1.video1 ? "opacity-video" : "hide"}`}
                            autoPlay={true}
                            loop={true} muted={true}>
                            <source src="videos/second-video.mp4" type="video/mp4"/>
                        </video>
                        <Box/>
                        <Box className="home-page-video-background">
                            <video
                                className={`home-page-video video-transition ${page2.video1 ? "opacity-video" : "hide"}`}
                                autoPlay={true}
                                loop={true} muted={true}>
                                <source src="videos/first-video.mp4" type="video/mp4"/>
                            </video>
                        </Box>
                        <Box className="home-page-video-background">
                            <video
                                className={`home-page-video video-transition ${page3.video1 ? "opacity-video" : "hide"}`}
                                autoPlay={true}
                                loop={true} muted={true}>
                                <source src="videos/third-video.mp4" type="video/mp4"/>
                            </video>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={`homepage-scroll homepage-scroll-lock ${lockScroll ? "homepage-scroll-lock" : ""}`}/>
        </Box>
    )
}