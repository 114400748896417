import "../css/Link.css";
import React from "react";
import {Box, Text} from "@chakra-ui/react";

export function TextLink({
                             text,
                             link,
                             customTextClass,
                             customParentClass,
                             whiteHex,
                             blackHex
                         }: { text: string, link: string, customTextClass?: string, customParentClass?: string, whiteHex?: string, blackHex?: string }) {
    const [t1, setT1] = React.useState<"o:i" | "o:m" | "o:o" | "c:i" | "c:m" | "c:o">('o:i');
    const [t2, setT2] = React.useState<"o:i" | "o:m" | "o:o" | "c:i" | "c:m" | "c:o">('o:i');

    const [hover, setHover] = React.useState(false);

    const [appear, setAppear] = React.useState(false);

    const t1R = React.useRef<NodeJS.Timeout[]>([]);
    const t2R = React.useRef<NodeJS.Timeout[]>([]);
    const t = React.useRef<NodeJS.Timeout[]>([]);

    React.useEffect(() => {
        doThings(setT1, true, true);
        t.current.push(setTimeout(() => doThings(setT2, true, false), 200));
        t.current.push(setTimeout(() => setAppear(true), 400));

        return () => {
            t.current.map(t => clearTimeout(t));
            t1R.current.map(t => clearTimeout(t));
            t2R.current.map(t => clearTimeout(t));
        }
    }, []);

    function doThings(func: React.Dispatch<React.SetStateAction<"o:i" | "o:m" | "o:o" | "c:i" | "c:m" | "c:o">>, open: boolean, tr1: boolean) {
        if (open) {
            (tr1 ? t1R.current : t2R.current).map(t => clearTimeout(t));
            func('o:i');
            const t1 = setTimeout(() => func('o:m'), 100);
            const t2 = setTimeout(() => func('o:o'), 500);

            if (tr1) t1R.current.push(t1, t2);
            else t2R.current.push(t1, t2);

        } else {
            (tr1 ? t1R.current : t2R.current).map(t => clearTimeout(t));
            func('c:i');
            const t1 = setTimeout(() => func('c:m'), 100);
            const t2 = setTimeout(() => func('c:o'), 500);

            if (tr1) t1R.current.push(t1, t2);
            else t2R.current.push(t1, t2);
        }
    }

    function getTransition(str: "o:i" | "o:m" | "o:o" | "c:i" | "c:m" | "c:o") {
        switch (str) {
            case 'o:i':
                return "text-box-open-intro";
            case 'o:m':
                return "text-box-open-middle";
            case 'o:o':
                return "text-box-open-outro";

            case 'c:i':
                return "text-box-close-intro";
            case 'c:m':
                return "text-box-close-middle";
            case 'c:o':
                return "text-box-close-outro";

            default:
                return "text-box-open-intro";
        }
    }

    function hoverHandler() {
        console.log("hover")
        setHover(true);
    }

    function unhoverHandler() {
        console.log("unhover")
        setHover(false);
    }

    return (
        <Box className={`text-box-container unselectable ${customParentClass}`} onMouseEnter={hoverHandler}
             onMouseLeave={unhoverHandler} onClick={() => window.open(link, "_blank")}>
            <Box className={`text-box-transition text-box-container-transition-1 ${getTransition(t1)}`}
                 style={blackHex ? {backgroundColor: blackHex} : {}}/>
            <Box className={`text-box-transition text-box-container-transition-2 ${getTransition(t2)}`}
                 style={whiteHex ? {backgroundColor: whiteHex} : {}}/>
            <Box className={`text-box-transition text-box-hover ${hover ? "text-box-hover-active" : ""}`}/>
            <Box className={`text-box-transition text-box-underline ${hover ? "text-box-underline-active" : ""}`} />
            <Text className={`text-box-text text-box-link-text ${customTextClass} ${appear ? "" : "text-box-text-hide"}`}>{text}</Text>
        </Box>
    )
}