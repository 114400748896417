export default {
    words: {
        introduction: "自己紹介",
        aboutMe: "自己紹介",
        aboutSMe: "自己紹介",
        languages: "プログラミング言語",
        languagesSTools: "プログラミング言語",
        projects: "プロジェクト",
        contact: "連絡",
        maintenance: "改修",
        keepScrolling: "スクロールを続ける",
    },
    page1: {
        im: "私は",
        takiyo: "たきよ",
        desu: "です",
        fullStackDeveloper: "私はフルスタック開発者です",
        aboutMe: "私は{strGlitch}から来た {numGlitch} 歳の開発者です。 現在、私は学生で、勉強とプログラミングの両方に時間を割く必要があります。 私は独学の開発者です。 プログラミングを始めたのは{numGlitch}歳の時。 私は JavaScript の discord music bot でプログラミングの旅を始めましたが、今でもそれは続いています。"
    },
    page2: {
        javascript: "JavaScript は私の最初の言語です",
        typescript: "私は TypeScript が大好きです",
        java: "私は Java を知っています",
        cpp: "私は C++ を学んでいます",
        nodejs: "私はバックエンドにこれを使用しています",
        electron: "私はデスクトップアプリにこれを使用しています",
        html: "\"言語\" はい、私はウェブ開発が大好きです",
        css: "\"言語\" はい、私はウェブ開発が大好きです",
        reactjs: "私はフロントエンドにこれを使用しています",
        reactts: "私はフロントエンドにこれを使用していますが、TS で",
        reactNative: "私はモバイルアプリにこれを使用しています",
        linux: "私はサーバーにこれを使用しています",
        mongodb: "私はデータベースにこれを使用しています",
        nginx: "私はサーバー（プロキシ）にこれを使用しています",
        azure: "私の VPS",
        webstorm: "私の IDE",
        clion: "私の IDE",
        github: "私のコードリポジトリ"
    },
    footer: {
        takiyos: "たきよさんの",
        portfolio: "ポートフォリオ",
        twitter: "ツイッター",
        github: "GitHub",
        discord: "Discord",
        patreon: "Patreon"
    }
}